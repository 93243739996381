import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaUser, FaEnvelope, FaLock, FaEye, FaEyeSlash, FaPhone, FaMailBulk } from 'react-icons/fa';
import { ArrowRight } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import Link from 'next/link';

const getEmailProvider = (email) => {
  if (!email) return null;
  
  const domain = email.split('@')[1].toLowerCase();
  
  const providers = {
    'gmail.com': 'https://gmail.com',
    'yahoo.com': 'https://mail.yahoo.com',
    'yahoo.ro': 'https://mail.yahoo.com',
    'outlook.com': 'https://outlook.live.com/mail',
    'hotmail.com': 'https://outlook.live.com/mail'
  };

  return providers[domain];
};

const RegisterClient = ({onRegisterSuccess, jobData }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isVerificationSent, setIsVerificationSent] = useState(false);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    const errors = [];
    
    // Validare nume
    if (!formData.name.trim()) {
      errors.push('Numele este obligatoriu');
    } else if (formData.name.trim().length < 3) {
      errors.push('Numele trebuie să aibă cel puțin 3 caractere');
    }

    // Validare email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      errors.push('Email-ul este obligatoriu');
    } else if (!emailRegex.test(formData.email)) {
      errors.push('Te rugăm să introduci o adresă de email validă');
    }

    // Validare telefon
    const phoneRegex = /^(07[0-9]{8}|\\+407[0-9]{8})$/;
    if (!formData.phone) {
      errors.push('Numărul de telefon este obligatoriu');
    } else if (!phoneRegex.test(formData.phone.replace(/\s/g, ''))) {
      errors.push('Te rugăm să introduci un număr de telefon valid (07xxxxxxxx)');
    }

    // Validare parolă
    if (!formData.password) {
      errors.push('Parola este obligatorie');
    } else if (formData.password.length < 6) {
      errors.push('Parola trebuie să aibă cel puțin 6 caractere');
    }

    // Validare confirmare parolă
    if (formData.password !== formData.confirmPassword) {
      errors.push('Parolele nu se potrivesc');
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const validationErrors = validateForm();
    if (validationErrors.length > 0) {
      setError(validationErrors[0]);
      return;
    }

    try {
      await onRegisterSuccess({
        name: formData.name.trim(),
        email: formData.email.trim().toLowerCase(),
        phone: formData.phone.replace(/\s/g, ''),
        password: formData.password
      });
      
      // Set verification sent state to true after successful registration
      setIsVerificationSent(true);
    } catch (error) {
      console.error('Error during registration:', error);
      const errorMessages = {
        'Email not confirmed': 'Te rugăm să îți confirmi adresa de email pentru a continua.',
        'User already registered': 'Există deja un cont cu această adresă de email.',
        'Invalid email': 'Te rugăm să introduci o adresă de email validă.',
        'Weak password': 'Parola trebuie să conțină minim 6 caractere.',
        'Password is required': 'Parola este obligatorie.',
      };
      
      setError(errorMessages[error.message] || 'A apărut o eroare. Te rugăm să încerci din nou.');
    }
  };

  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const steps = [
    { number: 1, status: "completed" },
    { number: 2, status: "current" },
    { number: 3, status: "upcoming" }
  ];

  return (
    <div className="w-full bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="flex items-center justify-center gap-2 py-3 border-b">
        {steps.map((step, idx) => (
          <div key={idx} className="flex items-center">
            <div className={`
              flex items-center justify-center w-6 h-6 rounded-full text-xs font-medium
              ${step.status === 'completed' ? 'bg-gray-900 text-white' : 
                step.status === 'current' ? 'border-2 border-gray-900 text-gray-900' :
                'bg-gray-100 text-gray-400'}
            `}>
              {step.status === 'completed' ? '✓' : step.number}
            </div>
            {idx < steps.length - 1 && (
              <div className={`
                w-8 h-0.5 mx-1
                ${step.status === 'completed' ? 'bg-gray-900' : 'bg-gray-200'}
              `} />
            )}
          </div>
        ))}
      </div>

      <div className="p-4">
        {isVerificationSent ? (
          <div className="text-center py-8">
            <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-6">
              <FaMailBulk className="w-8 h-8 text-gray-900" />
            </div>

            <h3 className="text-xl font-semibold text-gray-900 mb-4">
              Verifică-ți emailul
            </h3>
            <p className="text-gray-600 mb-6">
              Am trimis un link de confirmare la adresa <span className="font-semibold">{formData.email}</span>.<br/>
              Te rugăm să verifici inbox-ul și să confirmi contul.
            </p>

            <div className="space-y-4">
              {getEmailProvider(formData.email) ? (
                <a 
                  href={getEmailProvider(formData.email)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center w-full px-6 py-3 bg-gray-900 hover:bg-gray-800 
                    text-white rounded-lg transition-colors"
                >
                  Deschide Email
                  <ArrowRight className="w-4 h-4 ml-2" />
                </a>
              ) : (
                <div className="text-sm text-gray-600 bg-gray-50 p-4 rounded-lg">
                  Verifică email-ul în aplicația sau serviciul pe care îl folosești în mod obișnuit.
                </div>
              )}

              <Link 
                href="/login" 
                className="flex items-center justify-center w-full px-6 py-3 border border-gray-300 
                  text-gray-900 rounded-lg hover:bg-gray-50 transition-colors"
              >
                Mergi la Login
              </Link>
            </div>

            <div className="mt-8 p-4 bg-gray-50 rounded-lg border border-gray-200">
              <p className="text-sm text-gray-600">
                Nu ai primit emailul? Verifică și folderul de Spam sau contactează-ne la 
                <a href="mailto:contact@meseriaslocal.ro" className="font-medium text-gray-900 hover:underline"> contact@meseriaslocal.ro</a>
              </p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="text-center mb-4">
              <h3 className="text-xl font-semibold text-gray-900">
                Creează cont gratuit
              </h3>
              <p className="text-sm text-gray-700">
                Finalizează cererea și primește oferte de la meseriași verificați
              </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-3">
              <InputField
                icon={<FaUser className="w-4 h-4 text-gray-400" />}
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Nume complet"
                required
                className="w-full px-3 py-2 text-sm rounded-lg border"
              />
              
              <InputField
                icon={<FaEnvelope className="w-4 h-4 text-gray-400" />}
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email"
                required
                className="w-full px-3 py-2 text-sm rounded-lg border"
              />

              <InputField
                icon={<FaPhone className="w-4 h-4 text-gray-400" />}
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Telefon"
                required
                className="w-full px-3 py-2 text-sm rounded-lg border"
              />

              <PasswordField
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Parolă"
                showPassword={showPassword}
                toggleVisibility={() => togglePasswordVisibility('password')}
                className="w-full px-3 py-2 text-sm rounded-lg border"
              />

              <PasswordField
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                placeholder="Confirmă parola"
                showPassword={showConfirmPassword}
                toggleVisibility={() => togglePasswordVisibility('confirmPassword')}
                className="w-full px-3 py-2 text-sm rounded-lg border"
              />

              {error && (
                <div className="p-2 bg-red-50 border border-red-200 rounded-lg">
                  <p className="text-xs text-red-600">{error}</p>
                </div>
              )}

              <motion.button
                type="submit"
                className="w-full py-2.5 text-sm font-medium bg-gray-900 text-white rounded-lg
                  flex items-center justify-center gap-2"
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.99 }}
              >
                Creează Cont
                <ArrowRight className="w-4 h-4" />
              </motion.button>

              <p className="text-xs text-center text-gray-500">
                Prin crearea contului accepți{' '}
                <Link href="/terms" className="text-gray-900 hover:underline">Termenii</Link>
                {' '}și{' '}
                <Link href="/privacy" className="text-gray-900 hover:underline">Confidențialitatea</Link>
              </p>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

const InputField = ({ icon, className, ...props }) => (
  <div className="relative group">
    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 
      group-hover:text-gray-400 transition-colors duration-200">
      {icon}
    </span>
    <input
      {...props}
      className={`${className} text-gray-900 placeholder-gray-500 hover:border-gray-600 focus:border-gray-500`}
      style={{ paddingLeft: "2.5rem" }}
    />
  </div>
);

const PasswordField = ({ showPassword, toggleVisibility, className, ...props }) => (
  <div className="relative group">
    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 
      group-hover:text-gray-400 transition-colors duration-200">
      <FaLock />
    </span>
    <input
      {...props}
      type={showPassword ? "text" : "password"}
      className={`${className} text-gray-900 placeholder-gray-500 hover:border-gray-600 focus:border-gray-500`}
      style={{ paddingLeft: "2.5rem" }}
    />
    <button
      type="button"
      onClick={toggleVisibility}
      className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 
        hover:text-gray-400 focus:outline-none transition-colors duration-200"
    >
      {showPassword ? <FaEyeSlash /> : <FaEye />}
    </button>
  </div>
);

export default RegisterClient;