'use client'

import Link from 'next/link'
import Head from 'next/head'
import { ArrowRight, Shield, Clock, Star } from 'lucide-react'
import ServiceRequestForm from '@/components/forms/ServiceRequestForm'
import { tradesMapping } from '@/data/serviceCategories'

const DynamicHero = ({ 
  breadcrumbs,
  title,
  subtitle,
  description,
  showForm = true,
  showCTA = true,
  cityFromUrl,
  className = "",
  currentCategory,
  canonicalUrl,
  trustIndicators = [
    {
      icon: 'shield',
      title: 'Meseriași Verificați',
      description: 'Profesioniști verificați și evaluați'
    },
    {
      icon: 'clock',
      title: 'Răspuns Rapid',
      description: 'Răspuns în maxim 24 de ore'
    },
    {
      icon: 'check',
      title: 'Servicii Garantate',
      description: 'Calitate garantată a serviciilor'
    }
  ]
}) => {
  const relevantTrades = Object.entries(tradesMapping)
    .filter(([trade, [category]]) => category === currentCategory)
    .map(([trade]) => trade);

  const renderIcon = (iconName) => {
    const icons = {
      shield: Shield,
      clock: Clock,
      star: Star,
      check: Star
    };
    const IconComponent = icons[iconName];
    return IconComponent ? <IconComponent className="w-6 h-6 text-blue-400" /> : null;
  };

  const baseUrl = 'https://meseriaslocal.ro/';
  const fullCanonicalUrl = canonicalUrl ? `${baseUrl}${canonicalUrl}` : `${baseUrl}${breadcrumbs?.[breadcrumbs.length - 1]?.href || ''}`;

  return (
    <>
      <Head>
        <link 
          rel="canonical" 
          href={fullCanonicalUrl}
          key="canonical"
        />
        <link 
          rel="alternate" 
          hrefLang="ro" 
          href={fullCanonicalUrl}
          key="alternate-ro"
        />
        <link 
          rel="alternate" 
          hrefLang="x-default" 
          href={fullCanonicalUrl}
          key="alternate-default"
        />
      </Head>

      <section 
        className={`relative overflow-hidden text-white min-h-[70vh] flex items-center justify-center ${className} py-12`}
        role="banner"
        aria-label="Hero section"
      >
        {/* Background Layers */}
        <div className="absolute inset-0">
          <div 
            className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-800 to-black" 
            aria-hidden="true"
          />
          <div 
            className="absolute inset-0 bg-gradient-to-t from-gray-900/50 to-transparent" 
            aria-hidden="true"
          />
        </div>

        <div className="relative z-10 container mx-auto px-6">
          {breadcrumbs && (
            <nav 
              className="mb-6" 
              aria-label="Breadcrumb"
              itemScope 
              itemType="https://schema.org/BreadcrumbList"
            >
              <ol className="flex flex-wrap items-center space-x-2 text-gray-400 text-sm">
                {breadcrumbs.map((crumb, index) => (
                  <li 
                    key={index} 
                    className="flex items-center"
                    itemProp="itemListElement" 
                    itemScope
                    itemType="https://schema.org/ListItem"
                  >
                    {index < breadcrumbs.length - 1 ? (
                      <>
                        <Link 
                          href={crumb.href} 
                          className="hover:text-gray-200 transition duration-300"
                          itemProp="item"
                        >
                          <span itemProp="name">{crumb.text}</span>
                        </Link>
                        <meta itemProp="position" content={index + 1} />
                        <span className="mx-2 text-gray-500" aria-hidden="true">›</span>
                      </>
                    ) : (
                      <>
                        <span className="text-gray-200" itemProp="name">{crumb.text}</span>
                        <meta itemProp="position" content={index + 1} />
                      </>
                    )}
                  </li>
                ))}
              </ol>
            </nav>
          )}

          <div className="grid lg:grid-cols-2 gap-8 lg:gap-12 items-center max-w-7xl mx-auto">
            <div className="space-y-8">
              <div className="space-y-4">
                <h1 
                  className="text-3xl sm:text-4xl lg:text-5xl font-bold animate-fade-in"
                  itemProp="headline"
                >
                  {title}
                </h1>

                {subtitle && (
                  <h2 
                    className="text-xl sm:text-2xl text-gray-300 font-light leading-relaxed animate-fade-in"
                    itemProp="alternativeHeadline"
                  >
                    {subtitle}
                  </h2>
                )}

                {description && (
                  <p 
                    className="text-gray-400 text-lg animate-fade-in"
                    itemProp="description"
                  >
                    {description}
                  </p>
                )}
              </div>

              {/* Trust Indicators cu Schema.org */}
              <div 
                className="grid grid-cols-1 sm:grid-cols-3 gap-6 animate-fade-in"
                itemScope 
                itemType="https://schema.org/ItemList"
              >
                {trustIndicators.map((indicator, index) => (
                  <div 
                    key={index} 
                    className="flex items-center space-x-3"
                    itemProp="itemListElement"
                    itemScope
                    itemType="https://schema.org/ListItem"
                  >
                    {renderIcon(indicator.icon)}
                    <div className="flex flex-col">
                      <span 
                        className="text-gray-300 font-medium"
                        itemProp="name"
                      >
                        {indicator.title}
                      </span>
                      <span 
                        className="text-gray-400 text-sm"
                        itemProp="description"
                      >
                        {indicator.description}
                      </span>
                    </div>
                    <meta itemProp="position" content={index + 1} />
                  </div>
                ))}
              </div>

              {showCTA && (
                <div 
                  className="pt-4 animate-fade-in hidden md:block"
                  itemProp="potentialAction" 
                  itemScope 
                  itemType="https://schema.org/RegisterAction"
                >
                  <Link 
                    href="/register/worker"
                    className="inline-flex items-center bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg font-medium transition duration-300"
                    itemProp="url"
                  >
                    <span itemProp="name">Devino Meseriaș Verificat</span>
                    <ArrowRight className="ml-2 w-5 h-5" aria-hidden="true" />
                  </Link>
                </div>
              )}
            </div>

            {showForm && (
              <div 
                className="lg:mt-0 animate-fade-in"
                itemScope 
                itemType="https://schema.org/ContactPoint"
              >
                <ServiceRequestForm 
                  cityFromUrl={cityFromUrl} 
                  category={currentCategory}
                  relevantTrades={relevantTrades}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default DynamicHero