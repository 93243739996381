'use client'

import React from 'react';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { pricingData } from '@/data/pricingData';
import { Clock, Check } from 'lucide-react';

const ServicesPricing = () => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          {/* SEO Optimized Header */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-12"
          >
            <h1 className="text-3xl font-bold text-gray-900 mb-4">
              {pricingData.meta.title}
            </h1>
            <p className="text-gray-600">
              {pricingData.meta.description}
            </p>
          </motion.div>

          {/* Pricing Categories */}
          <div className="space-y-8">
            {pricingData.categories.map((category, idx) => (
              <motion.div
                key={category.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: idx * 0.1 }}
                className="bg-white rounded-xl border border-gray-200 overflow-hidden shadow-sm"
              >
                <Link href={category.slug} className="block px-6 py-4 bg-gray-50 hover:bg-gray-100 transition-colors">
                  <h2 className="text-xl font-semibold text-gray-900">{category.title}</h2>
                  <p className="text-gray-600 text-sm mt-1">{category.description}</p>
                </Link>
                
                <div className="p-6">
                  <ul className="space-y-6">
                    {category.items.map((item) => (
                      <li key={item.id} className="border-b border-gray-100 pb-4 last:border-0">
                        <Link href={item.link} className="group">
                          <div className="flex justify-between items-start mb-2">
                            <span className="text-gray-900 font-medium group-hover:text-blue-600 transition-colors">
                              {item.service}
                            </span>
                            <span className="text-gray-900 font-semibold">{item.price}</span>
                          </div>
                          <p className="text-gray-600 text-sm" 
                             dangerouslySetInnerHTML={{ __html: item.details }} />
                          <div className="flex items-center mt-2 text-sm text-gray-500">
                            <Clock className="w-4 h-4 text-gray-400 mr-2" />
                            Durată estimată: {item.duration}
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>

                  <div className="mt-6 pt-6 border-t border-gray-100">
                    <h3 className="text-gray-900 font-medium mb-3">Include:</h3>
                    <ul className="space-y-2">
                      {category.features.map((feature, idx) => (
                        <li key={idx}>
                          <Link 
                            href={feature.link}
                            className="flex items-center text-gray-600 text-sm hover:text-blue-600 transition-colors"
                          >
                            <Check className="w-4 h-4 text-green-500 mr-2" />
                            {feature.text}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

    
        </div>
      </div>
    </section>
  );
};

export default ServicesPricing;