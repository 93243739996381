'use client'

import React from 'react';
import Link from 'next/link';
import { cities, getCitySlug } from '@/data/cities';
import { citiesServicesData } from '@/data/citiesServicesData';
import { motion } from 'framer-motion';
import { Check, MapPin } from 'lucide-react';
import { Card, CardContent } from "@/components/ui/card";

const ServicesCities = () => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          {/* Header Section */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-12"
          >
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              {citiesServicesData.meta.title}
            </h1>
            
            <p className="text-gray-600 mb-8 max-w-3xl mx-auto">
              {citiesServicesData.meta.description}
            </p>

            <div className="flex flex-wrap justify-center gap-4 mb-12">
              {Object.values(citiesServicesData.cta).map((button, idx) => (
                <Link 
                  key={idx}
                  href={button.link}
                  target="_blank"
                  rel="dofollower"
                  className={`px-6 py-3 font-medium rounded-lg transition-all shadow-sm hover:shadow-md ${
                    button.variant === 'primary' 
                      ? 'bg-gray-900 hover:bg-gray-800 text-white'
                      : 'bg-white border border-gray-200 hover:border-gray-300 text-gray-900'
                  }`}
                >
                  {button.text}
                </Link>
              ))}
            </div>
          </motion.div>

          {/* Cities Grid */}
          {citiesServicesData.cities.slice(0, 8).map((city, cityIndex) => (
            <motion.div 
              key={city.slug}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: cityIndex * 0.1 }}
              className="mb-16 last:mb-0"
            >
              <div className="flex items-center gap-3 mb-6">
                <MapPin className="w-6 h-6 text-gray-400" />
                <h2 className="text-2xl font-bold text-gray-900">
                  Meseriași Profesioniști în {city.name}
                </h2>
              </div>

              {/* Services for each city */}
              {Object.entries(citiesServicesData.services).map(([key, service]) => (
                <Card key={key} className="mb-8 last:mb-0 border-gray-200 shadow-sm hover:shadow-md transition-all">
                  <CardContent className="p-6">
                    <div className="grid lg:grid-cols-2 gap-8">
                      {/* Service Description */}
                      <div>
                        <Link 
                          href={`${service.slug}/${getCitySlug(city.name)}`}
                          className="group inline-block"
                        >
                          <h3 className="text-xl font-semibold text-gray-900 group-hover:text-blue-600 transition-colors mb-3">
                            {service.title} {city.name}
                          </h3>
                        </Link>
                        
                        <div 
                          className="text-gray-600 mb-6 prose prose-sm"
                          dangerouslySetInnerHTML={{ __html: service.description }}
                        />
                        
                        {/* Benefits Grid */}
                        <div className="grid sm:grid-cols-2 gap-4">
                          {service.benefits.map((benefit, idx) => (
                            <Link
                              key={idx}
                              href={benefit.link}
                              className="flex items-center text-gray-600 hover:text-blue-600 transition-colors group"
                            >
                              <Check className="w-4 h-4 text-green-500 mr-2 flex-shrink-0" />
                              <span className="text-sm">{benefit.text}</span>
                            </Link>
                          ))}
                        </div>
                      </div>

                      {/* Specialties */}
                      <div className="bg-gray-50 rounded-xl p-6">
                        <h4 className="font-medium text-gray-900 mb-4">
                          Servicii Disponibile:
                        </h4>
                        <div className="grid md:grid-cols-2 gap-3">
                          {service.specialties.map((specialty, idx) => (
                            <Link 
                              key={idx}
                              href={`${specialty.link}/${getCitySlug(city.name)}`}
                              className="text-gray-600 hover:text-blue-600 transition-colors flex items-center"
                            >
                              <span className="mr-2">{specialty.icon}</span>
                              {specialty.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>

                   
                  </CardContent>
                </Card>
              ))}

            </motion.div>
          ))}

       
        </div>
      </div>
    </section>
  );
};

export default ServicesCities;